import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatDatepickerModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from '../environments/environment';
import { AppBpmsComponent } from './app.bpms.component';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { TarefaService } from './components/tarefa/tarefa.service';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PageErrorsModule } from './layouts/errors/page-errors.module';
import { AuthenticatedUserService } from './services/authenticated-user.service';
import { RoutingStateService } from './services/routing-state.service';
import { CustomSnackBarComponent } from "./shared/snack-bar/custom-snack-bar.component";
import { AppDateAdapter, APP_DATE_FORMATS } from './util/date-adapter.util.service';
import { UtilService } from './util/util.service';

registerLocaleData(localePt);




@NgModule({
    providers: [
        RoutingStateService,
        AuthenticatedUserService,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        TranslateService,
        Title,
        UtilService,
        CookieService,
        TarefaService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: MatPaginatorIntl, useValue: CustomPaginator() },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        {
            provide: DateAdapter,
            useClass: AppDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },

    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
        }),
        NgxMaskModule.forRoot({ validation: true }),
        NgxPaginationModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        PageErrorsModule,
        MatSnackBarModule,
        MatIconModule,
        MatDatepickerModule,
        FlexModule
    ],
    declarations: [
        AppBpmsComponent,
        AdminLayoutComponent,
        CustomSnackBarComponent
    ],
    exports: [
        RouterModule,
        MatMenuModule,
        NgxMaskModule,
        CustomSnackBarComponent
    ],
    entryComponents: [CustomSnackBarComponent],
    bootstrap: [AppBpmsComponent]
})
export class AppModule {
    constructor() {
        console.log('[bpms] ambiente de ' + environment.name)
    }
}

function CustomPaginator(): MatPaginatorIntl {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Itens por página:';
    customPaginatorIntl.nextPageLabel = 'Próxima página';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.firstPageLabel = 'Primeira página';
    customPaginatorIntl.lastPageLabel = 'Ultima página';
    customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

    return customPaginatorIntl;
}

