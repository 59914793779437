/* tslint:disable:member-ordering */
import {Tarefa} from "../../components/model";

export class Page {

    constructor(
        public content: Tarefa[],
        public pageable: Pageable,
        public totalElements: number,
        public last: boolean,
        public totalPages: number,
        public size: number,
        public number: number,
        public sort: Sort,
        public numberOfElements: number,
        public first: boolean,
        public empty: boolean,
        public prazo: Prazo,
        public atribuicao: Atribuicao,
        public complementacoes: number,
        public requerimentos: number
) {
    }

    static fromJson(content: Tarefa[], json: any): Page {
        return new Page(
            content,
            Pageable.fromJson(json.pageable ? json.pageable : {}),
            json.totalElements,
            json.last,
            json.totalPages,
            json.size,
            json.number,
            Sort.fromJson(json.sort ? json.sort : {}),
            json.numberOfElements,
            json.first,
            json.empty,
            Prazo.fromJson(json.prazo),
            Atribuicao.fromJson(json.atribuicao),
            json.complementacoes,
            json.requerimentos
        );
    }

    static empty() {
        return Page.fromJson([], '');
    }
}

export class Pageable {
    constructor(
        public sort: Sort,
        public offset: number,
        public pageNumber: number,
        public pageSize: number,
        public unpaged: boolean,
        public paged: boolean
    ) {
    }

    static fromJson(json: any): Pageable {
        return new Pageable(
            Sort.fromJson(json.sort ? json.sort : {}),
            json.offset,
            json.pageNumber,
            json.pageSize,
            json.unpaged,
            json.paged
        );
    }
}

export class Sort {
    constructor(
        public sorted: boolean,
        public unsorted: boolean,
        public empty: boolean
    ) {
    }

    static fromJson(json: any): Sort {
        return new Sort(
            json.sorted,
            json.unsorted,
            json.empty
        );
    }
}

export class Prazo {
    constructor(
        public semControle: number,
        public noPrazo: number,
        public atencao: number,
        public vencendo: number,
        public vencido: number,
    ) {
    }

    public static getTitulosGrafico(): string[] {
        return ['Sem Controle', 'No Prazo', 'Atenção com Prazo', 'Vencendo Prazo', 'Prazo Vencido'];
    }

    public static getCoresGrafico(): string[] {
        return ['#95a5a6', '#2ecc71', '#f1c40f', '#d35400', '#c0392b'];
    }

    public getDadosGrafico(): number[] {
        return [this.semControle, this.noPrazo, this.atencao, this.vencendo, this.vencido];
    }

    public compareDadosGrafico(dados: number[], legendas: any[]): boolean {
        return this.getDadosGrafico().every((dado, index) => legendas[index].hidden || dados[index] === dado);
    }

    public getDadosGraficoNg(): string {
        return `{"name": "Sem Controle", "value": ${this.semControle},
        {"name": "No Prazo", "value": ${this.noPrazo},
        {"name": "Atenção com Prazo", "value": ${this.atencao},
        {"name": "Vencendo Prazo", "value": ${this.vencendo},
        {"name": "Prazo Vencido", "value": ${this.vencido},
        `;
    }

    static fromJson(json: any): Prazo {
        if (json === undefined || json === null) {
            return null;
        }
        return new Prazo(
            json.semControle,
            json.noPrazo,
            json.atencao,
            json.vencendo,
            json.vencido
        );
    }
}

export class Atribuicao {
    constructor(
        public grupo: number,
        public meus: number,
    ) {
    }

    public static getTitulosGrafico(): string[] {
        return ['Minhas Tarefas', 'Meus grupos'];
    }

    public getDadosGrafico(): number[] {
        return [this.meus, this.grupo];
    }

    public static getCoresGrafico(): string[] {
        return ['#258639', '#2c53a0'];
    }

    public compareDadosGrafico(dados: number[], legendas: any[]): boolean {
        return this.getDadosGrafico().every((dado, index) => legendas[index].hidden || dados[index] === dado);
    }

    static fromJson(json: any): Atribuicao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Atribuicao(
            json.grupo,
            json.meus
        );
    }
}
