import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResponseUtil} from "../components/util/ResponseUtil";

@Injectable({
    providedIn: 'root'
})
export class AvisosDestinatarioService {
    urlResource: string = environment.URL_SCORP_API + '/api/aviso-destinatario';

    constructor(
        protected http: HttpClient,
    ) {
    }

    public obterQtdeAvisosNaoLidos(): Observable<any> {
        const url = `${this.urlResource}/obter-quantidade-avisos-n-lidos`;
        return ResponseUtil.reponseToJson(
            this.http.get(url));
    }

    public atualizarAberto(idAvisoDestinatario: number): Observable<any> {
        const url = `${this.urlResource}/atualizar-aberto/${idAvisoDestinatario}`;
        return ResponseUtil.reponseToJson(
            this.http.put(url, {}));
    }

    public atualizarLido(avisoDestintario: any): Observable<any> {
        const url = `${this.urlResource}/atualizar-lido`;
        return ResponseUtil.reponseToJson(
            this.http.put(url, avisoDestintario));
    }

    public obterQtdeAvisosNaoLidosPorRequerimento(numeroRequerimento: string): Observable<any> {
        const url = `${this.urlResource}/obter-quantidade-avisos-requerimento`;
        let queryParams = new HttpParams();
        queryParams = queryParams.set("numeroRequerimento", numeroRequerimento);
        return ResponseUtil.reponseToJson(
            this.http.get(url, {params: queryParams}));
    }
}