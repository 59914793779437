import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-error-500',
    templateUrl: './error-500.component.html',
    styleUrls: ['../error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error500Component {
    /**
    * Constructor
    */
    constructor() {
    }
}
