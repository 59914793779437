import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const KnownErrors = [401, 404];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //return next.handle(req).pipe(catchError(error => {
        //    if (error instanceof HttpErrorResponse) {
        //        this.gotoErrorPage(error.status);
        //    }

        //    return throwError(error);
        //}));
        return next.handle(req);
    }

    private gotoErrorPage(status: number): Promise<boolean> {
        if (KnownErrors.includes(status)) {
            return this.router.navigateByUrl(`/error/error-${status}`, { replaceUrl: true });
        }

        return this.router.navigateByUrl('/error/error-500', { replaceUrl: true });
    } 
}