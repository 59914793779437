import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResponseUtil} from "../components/util/ResponseUtil";

@Injectable({
  providedIn: 'root'
})
export class AvisosService {
  urlResource: string = environment.URL_SCORP_API + '/api/avisos';

  constructor(
    protected http: HttpClient,
  ) {
  }

  public buscarTodosAvisos(page: any, size: any, numeroRequerimento: string, nLido: string, buscarPorGrupos: string,
                           buscarMeusRequerimentos: string,): Observable<any> {
    const url = `${this.urlResource}/buscar-todos-avisos`;

    let queryParams = new HttpParams();
    queryParams = queryParams.append('size', size)
    queryParams = queryParams.append('page', page)

    if (numeroRequerimento) {
      queryParams = queryParams.set("numeroRequerimento", numeroRequerimento);
    }

    if (nLido == "S") {
      queryParams = queryParams.set("nLido", "S");
    } else if (buscarPorGrupos == "S") {
      queryParams = queryParams.set("buscarPorGrupo", "S");
    } else if (buscarMeusRequerimentos == "S") {
      queryParams = queryParams.set("buscarMeusRequerimentos", "S");
    }

    return ResponseUtil.reponseToJson(
      this.http.get(url, {params: queryParams}));
  }
}
