import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UtilService } from 'app/util/util.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FiltroGrupoValue, FiltroPrazoValue, FiltroTarefas } from '../../dashboard/filtro-tarefas.model';
import { AuthenticationService } from '../../services/authentication.service';
import { Page } from '../../shared/models/page.model';
import { DominioDto, Tarefa, TarefaAssignee, TarefaCount } from '../model';
import { ResponseUtil } from '../util/ResponseUtil';

@Injectable({
    providedIn: 'root'
})
export class TarefaService {
    urlResource: string = environment.URL_GATEWAY + '/api/tarefas';
    constructor(
        protected http: HttpClient,
        private authenticationService: AuthenticationService,
        private utilService: UtilService,
    ) {
        
    }

    public page(paginator: MatPaginator, filtro: FiltroTarefas, renovarCache: boolean): Observable<Page> {
        let params: HttpParams = new HttpParams();
        params = params.append('size', paginator.pageSize.toString())
        params = params.append('page', paginator.pageIndex.toString())
        if (filtro.numero) {
            params = params.append('numero', filtro.numero)
        }
        if (filtro.cpfCnpj) {
            params = params.append('cpfCnpj', filtro.cpfCnpj)
        }
        if (renovarCache) {
            params = params.append('renovarCache', String(renovarCache))
        }
        // Se todos os valores do enum estão presentes, não manda filtro
        if (filtro.grupo && !Object.keys(FiltroGrupoValue).every(pEnum => filtro.grupo.some(pFiltro => pFiltro === pEnum))) {
            filtro.grupo.forEach(grupo => params = params.append('grupo', grupo.toString()))
        }
        // Se todos os valores do enum estão presentes, não manda filtro
        if (filtro.prazo && !Object.keys(FiltroPrazoValue).every(pEnum => filtro.prazo.some(pFiltro => pFiltro === pEnum))) {
            filtro.prazo.forEach(prazo => params = params.append('prazo', prazo.toString()))
        }

        if (filtro.nomesTarefa) {
           params = params.append('nomesTarefa', String(filtro.nomesTarefa))
        }


        // this.utilService.formatDate (pegar do licenciamento)

        params = filtro.prioridade ? params.append('prioridade', filtro.prioridade) : params;
        params = filtro.dataInicial ? params.append('dataInicial',this.utilService.zonedDateTimeFormat(filtro.dataInicial, 'YYYY-MM-DDTHH:mm:ss.000[Z]')) : params;
        params = filtro.dataFinal ? params.append('dataFinal', this.utilService.zonedDateTimeFormat(filtro.dataFinal, 'YYYY-MM-DDT[23]:[59]:[59].[999][Z]')) : params;
        params = filtro.ordemCampo ? params.append('ordemCampo', filtro.ordemCampo ) : params;
        params = filtro.ordem ? params.append('ordem', filtro.ordem ) : params;
        params = filtro.grupos ? params.append('grupos', filtro.grupos) : params;

        return this.http.get(`${this.urlResource}/page`, {params: params}).pipe(
            map(response => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }

    protected jsonToPage(json: any): Page {
        // refs: #6354 - Remove dup
        json.content = ((json.content || []) as Array<any>).filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
        return Page.fromJson(
            this.jsonToResources(json.content),
            json
        );
    }

    protected jsonToResources(json: any): Tarefa[] {
        const resources: Tarefa[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: Tarefa) => {
                e.variables = e.variables &&  typeof e.variables == 'string' ? JSON.parse(<string>e.variables) : e.variables;
                resources.push(e);
            });
        }
        return resources;
    }

    async getListaNomeTarefasUsuario(): Promise<Observable<DominioDto[]>> {
        return this.http.get<DominioDto[]>(`${this.urlResource}/listar-nome-tarefas-usuario`)
    }

    public contaTarefasPUsuario(): Observable<TarefaCount> {
        const url = `${this.urlResource}/quantidade`;
        return ResponseUtil.reponseToJson(
            this.http.get(url));
    }

    public delegaTarefas(tarefaAssignee: TarefaAssignee): Observable<Tarefa[]> {
        const url = `${this.urlResource}/delegar`;
        return this.http.put(url, tarefaAssignee).pipe(catchError(this.handleError.bind(this)));
    }

    public redirect(url: string): void {
        // this.authProviderService.openUrlFrame(`${environment.URL_LICENCIAMENTO}` + url);
        if (url.includes('ressarcimento-de-taxa')) {
            this.authenticationService.openUrlFrame(`${environment.URL_ARRECADACAO}` + url);
        } else {
            this.authenticationService.openUrlFrame(`${environment.URL_LICENCIAMENTO}` + url);
        }
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    devolverTarefa(id: any) {
        const url = `${this.urlResource}/devolverGrupo/${id}`;
        return this.http.post(url, null).pipe(catchError(this.handleError.bind(this)));
    }

}
