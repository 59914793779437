import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {MensagemDialogComponent} from "./mensagem-dialog/mensagem-dialog.component";
import {MatPaginator} from '@angular/material';
import {MatTableDataSource} from "@angular/material/table";
import {AvisosService} from "../../services/avisos.service";
import {AvisosDestinatarioService} from "../../services/avisos-destinatario.service";
import {BehaviorSubject} from "rxjs";
import {PageEvent} from "@angular/material/paginator";
import {SnackBarService} from "../../shared/snack-bar/snack-bar.service";

@Component({
  selector: 'app-avisos-dialog',
  templateUrl: './avisos-dialog.component.html',
  styleUrls: ['./avisos-dialog.component.scss']
})
export class AvisosDialogComponent implements OnInit {
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  public loadingStream: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  filtro: string;
  page: any = 0;
  size: any = 5;
  totalElements: number = 0;

  isAlertaRequerimento: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['lido', 'tipoAlerta', 'numeroRequerimento', 'data', 'acoes'];
  numeroProtocolo: any = "";
  nLido: any = "N";
  buscarPorGrupos: any = "N";
  buscarMeusRequerimentos: any = "N";

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly dialog: MatDialog,
    private readonly avisosService: AvisosService,
    private readonly avisoDestinatarioService: AvisosDestinatarioService,
    private readonly snackBarService: SnackBarService
  ) {
  }

  async ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.isAlertaRequerimento = this.data.isAlertaRequerimento;
    this.numeroProtocolo = this.data.numeroRequerimento;
    await this.buscarTodosAvisos();
  }

  async buscarTodosAvisos() {
    this.loadingStream.next(true);
    const numeroProtocolo = this.numeroProtocolo ? this.numeroProtocolo.trim() : null;
    await this.avisosService.buscarTodosAvisos(this.page, this.size, numeroProtocolo, this.nLido, this.buscarPorGrupos, this.buscarMeusRequerimentos).toPromise().then(avisos => {
      this.dataSource.data = avisos.content;
      this.totalElements = avisos.totalElements;
      this.loadingStream.next(false);
    }, (e) => {
      this.loadingStream.next(false);
      this.snackBarService.showError(e.error.errors[0])
    })
  }

  changeFiltro(event) {
    switch (event) {
      case 'nLidos':
        this.buscarMeusRequerimentos = "N";
        this.buscarPorGrupos = "N";
        this.nLido = "S"
        this.numeroProtocolo = '';
        break;
      case 'grupos':
        this.nLido = "N"
        this.buscarMeusRequerimentos = "N";
        this.buscarPorGrupos = "S";
        this.numeroProtocolo = '';
        break;
      case 'processos':
        this.nLido = "N"
        this.buscarPorGrupos = "N";
        this.buscarMeusRequerimentos = "S";
        this.numeroProtocolo = '';
        break;
      case 'requerimentos':
        this.nLido = "N"
        this.buscarPorGrupos = "N";
        this.buscarMeusRequerimentos = "N";
        break;
    }
  }

  onPageChanged(event: PageEvent) {
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.buscarTodosAvisos();
  }

  filtrar() {
    //resetando pagina ao trocar o filtro
    this.page = 0;
    this.size = 5;
    this.buscarTodosAvisos();
  }

  async atualizarLido(item: any) {
    this.loadingStream.next(true);
    item.avisoDestinatario.lido == item.avisoDestinatario.lido;
    await this.avisoDestinatarioService.atualizarLido(item.avisoDestinatario).toPromise().then(() => {
    }, (e) => {
      this.loadingStream.next(false);
      this.snackBarService.showError(e.error.errors[0])
    })
    this.loadingStream.next(false);
    await this.buscarTodosAvisos();
  }

  visualizarAviso(item: any) {
    const dialogRef = this.dialog.open(MensagemDialogComponent, {
      data: {mensagem: item.aviso.mensagem},
      width: '60%'
    });

    dialogRef.afterClosed().subscribe(async () => {
      if (item.avisoDestinatario.aberto == 'N') {
        this.loadingStream.next(true);
        await this.avisoDestinatarioService.atualizarAberto(item.avisoDestinatario.id).toPromise().then(() => {
        }, (e) => {
          this.loadingStream.next(false);
          this.snackBarService.showError(e.error.errors[0])
        });

        await this.buscarTodosAvisos();
      }

    });
  }
}
