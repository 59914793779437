import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AuthenticationService} from './services/authentication.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppBpmsComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _authenticationService: AuthenticationService
    ) {

    }

    ngOnInit(): void {
        let accessToken: string = null;
        let urlPortal: string = null;
        if (window && window.location) {
            if (window.location.search && window.location.search.length > 0) {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams) {
                    if (urlParams.get('accessToken')) {
                        accessToken = urlParams.get('accessToken')
                    }
                    if (urlParams.get('urlPortal')) {
                        urlPortal = urlParams.get('urlPortal')
                    }
                }
            } else if (window.location.href && window.location.href.split('?')[1]) {
                const params: any = window.location.href.split('?')[1].split('&').reduce(function (result, param) {
                    const [key, value] = param.split('=');
                    result[key] = value;
                    return result;
                }, {});
                if (params) {
                    if (params.accessToken) {
                        accessToken = params.accessToken;
                    }
                    if (params.urlPortal) {
                        urlPortal = params.urlPortal;
                    }
                }
            }
        }
        //if (accessToken != null && urlPortal != null) {
        //    this._authenticationService.loginWithToken(accessToken).subscribe(() => {
        //        parent.postMessage('{ "type": "siga", "message": "login-success", "system": "bpms"}', urlPortal);
        //    }, (error: any) => {
        //        console.error(error);
        //        parent.postMessage('{ "type": "siga", "message": "login-error", "system": "bpms"}', urlPortal);
        //    });
        //}
    }


}
