import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AvisosDialogComponent} from './avisos-dialog/avisos-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {FlexModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {FormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { MensagemDialogComponent } from './avisos-dialog/mensagem-dialog/mensagem-dialog.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatBadgeModule} from "@angular/material/badge";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatIconModule,
        FlexModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        FormsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatBadgeModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        AvisosDialogComponent,
        MensagemDialogComponent],
    exports: [],
    entryComponents: [
        AvisosDialogComponent,
        MensagemDialogComponent
    ]
})
export class ComponentsModule {
}
