import {BaseEnum} from '../shared/models/base-enum.model';

export enum FiltroGrupoValue {
    MINHAS_TAREFAS = 'MINHAS_TAREFAS',
    MEUS_GRUPOS = 'MEUS_GRUPOS'
}


export enum FiltroPrazoValue {
    SEM_CONTROLE = 'SEM_CONTROLE',
    NO_PRAZO = 'NO_PRAZO',
    ATENCAO = 'ATENCAO',
    VENCENDO = 'VENCENDO',
    VENCIDO = 'VENCIDO'
}

export class FiltroTarefas {

    static grupos: BaseEnum[] = [
        {valor: FiltroGrupoValue.MINHAS_TAREFAS, descricao: 'Minhas tarefas'},
        {valor: FiltroGrupoValue.MEUS_GRUPOS, descricao: 'Meus grupos'}
    ];

    static prazos: BaseEnum[] = [
        {valor: FiltroPrazoValue.SEM_CONTROLE, descricao: 'Sem controle'},
        {valor: FiltroPrazoValue.NO_PRAZO, descricao: 'No prazo'},
        {valor: FiltroPrazoValue.ATENCAO, descricao: 'Atenção'},
        {valor: FiltroPrazoValue.VENCENDO, descricao: 'Vencendo'},
        {valor: FiltroPrazoValue.VENCIDO, descricao: 'Vencido'},
    ];

    constructor(
        public cpfCnpj: string,
        public numero: string,
        public renovarCache: boolean,
        public grupo: string[],
        public prazo: string[],
        public nomesTarefa: string[],
        public prioridade: string = '90',
        public dataInicial: string,
        public dataFinal: string,
        public ordemCampo: string = 'DATAINICIAL',
        public ordem: string = 'ASC',
        public grupos: string,
    ) {
    }

}
