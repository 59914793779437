export const environment = {
    production: true,
    hmr: false,
    name: 'production',
    URL_GATEWAY: 'https://bpms-api.sema.mt.gov.br',
    URL_WSO2_AUTH: '',
    URL_WSO2_USER_INFO: '',
    URL_PORTAL: 'https://portal.sema.mt.gov.br',
    URL_PORTAL_API: 'https://portal-api.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'https://licenciamento.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'https://licenciamento-api.sema.mt.gov.br',
    URL_ARRECADACAO: 'https://arrecadacao.sema.mt.gov.br',
    URL_SCORP_API: 'https://scorp-api.sema.mt.gov.br',
    OAUTH_CLIENT_KEY: 'M4xK_8Jjhf0XVdB7cpBiP0JoZ00a',
    OAUTH_CLIENT_SECRET: 'aqEtaGeG0Yc5gh4SW0g2_OeEQYYa',
    OAUTH_SCOPES: 'email openid profile address photourl picture',
    IDENTITY_COOKIE_NAME: "br.gov.mt.sema.identity"
    
};

