import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {CurrentUser} from '../components/models/current-user.model';
import {AuthenticatedUserService} from './authenticated-user.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

    private _currentUser: CurrentUser;

    constructor(
        private authenticatedUserService: AuthenticatedUserService
    ) {
        this.refreshUserInfo();
    }

    public get currentUser(): CurrentUser {
        this.refreshUserInfo();

        return this._currentUser;
    }

    private refreshUserInfo() {
        this._currentUser = null;

        if (this.authenticatedUserService.isAuthenticated) {
            const identityToken = this.authenticatedUserService.identityToken;

            this._currentUser = new CurrentUser(
                identityToken.sub,
                identityToken.details.nome,
                identityToken.details.servidor
            );
        }
    }

    public openUrlFrame(url: string): void {
        window.location.href = `${url}?urlPortal=${environment.URL_PORTAL}`;
    }

}
