export class CurrentUser {

    static fromJson(json: any): CurrentUser {
        if (!json) {
            return null;
        }

        return new CurrentUser(
            json.identity.value,
            json.identity.details.nome,
            json.identity.details.servidor,
            '',
            ''
        );
    }
    constructor(
        public sub?: string,
        public nome?: string,
        public servidor?: boolean,
        public iat?: string,
        public exp?: string,
    ) {

    }
}
